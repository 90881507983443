<template>
  <div>
    <form @submit.prevent="$salvarAbertura">
        <div class="row">
        <div class="col-8 col-md-4">
          <b-form-group label="Caixa nº">
            <b-input
              type="number"
              :value="numCaixaValue"
              @input="setNumCaixa"
              disabled
              class="text-center"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group label="Valor Abertura / Fundo">
            <b-input
              :value="valorAberturaValue"
              @input="setValorAbertura"
              v-money="moneyMask"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-3 text-center">
          <hr />
          <b-button type="submit" @click="$salvarAbertura" variant="primary"
            ><b-icon-eject /> Abrir Caixa</b-button
          >
          <b-button variant="secondary" @click="$emit('cancelarAbertura')"
            ><i class="fa fa-times" /> Cancelar</b-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CaixaLib from "../../libs/CaixasLib.js";
import moment from "moment";
export default {
  props: {
    numCaixa: [Number, String],
    valorAbertura: [Number, String],
    independent: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [String, Date],
      default: moment().format("YYYY-MM-DD HH:mm:ss"),
    },
    empresa_id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      private: {
        $valorAbertura: 0,
        $numCaixa: this.numCaixa?this.numCaixa:1,
      },
    };
  },

  methods: {
    setValorAbertura(value) {
   
      this.$emit("update:valorAbertura", value);
      if (this.independent) {
        this.private.$valorAbertura = value;
      }
    },
    setNumCaixa(value) {

      this.$emit("update:numCaixa", value);

      if (this.independent) {
        this.private.$numCaixa = value;
      }
    },
    async $salvarAbertura(value) {
      this.$emit("salvarAbertura", value);
  // console.log('value', value, 'independent', this.independent);
      if (this.independent) {
        await this.salvarAbertura(value);
      }
    },

    async salvarAbertura(e, abertura = true) {
      // console.log('internal variables',this.private, 'emrpesa_id', this.empresa_id);
      if (!this.private.$numCaixa || !this.empresa_id) {
        this.$swal({
          title: "Atenção",
          html: `<b>Aconteceu algum erro inesperado!</b></b><hr/><span class='text-left'> <b>Erro na clinica.</b> <small>*Possívelmente a agenda é em um consultório, tente abrir o caixa na tela de caixa.</small> </span>`,
          icon: "error",
          allowEscapeKey: true,
        });
        return;}
      if (e) {
        e.preventDefault();
      }

      let obj = {
        caixa_num: this.private.$numCaixa,
        empresa_id: this.empresa_id,
        dt_caixa: moment(this.data).format("YYYY-MM-DD"),
      };
      if (abertura) {
        obj.vl_abertura = this.formatValorReal(this.private.$valorAbertura);
        obj.user_abertura_id = this.$store.state.auth.user.id;
      }
      obj[abertura ? "dt_abertura" : "dt_fechamento"] =
        moment(this.data).format("YYYY-MM-DD HH:mm:ss")

      let result = await CaixaLib.registraControle(obj);
      this.$swal({
        title: result
          ? "Caixa " + (abertura ? "aberto" : "fechado") + " com sucesso!"
          : "Erro ao realizar a transação de caixa",
        toast: true,
        position: "top-end",
        timer: 2200,
        icon: result ? "success" : "error",
        showConfirmButton: false,
      });

      if (result) {
        this.$emit("caixa", result);
      }
    },
  },
  computed: {
    numCaixaValue: {
      get() {
        return this.independent ? this.private.$numCaixa : this.numCaixa;
      },
    },
    valorAberturaValue: {
      get() {
        return this.independent ? this.private.$valorAbertura : this.valorAbertura;
      },
    },
  },
};
</script>

<style></style>
