<template>
  <div>
    <!-- {{item}} -->

    <div class="row">
      <div class="col-12">
        <label>Tipo de Venda</label>
        <b-radio-group
          v-model="item.tipoVenda"
          :disabled="value != null"
        >
          <b-radio value="Consulta">Consulta / Exame</b-radio>
          <b-radio value="Produto">Produto</b-radio>
          <b-radio value="Exame">Solicitação de Exames (GUIA)</b-radio>
          <b-radio value="Fidelização">Fidelização</b-radio>
        </b-radio-group>
      </div>
    </div>

    <div
      class="col-12 mt-2 pr-0 pl-0"
      v-if="
        (fidelizacaoObj ||
          (convenio &&
            Array.isArray(convenio.dependentes) &&
            convenio.dependentes.length > 0)) &&
        ['Consulta', 'Exame'].includes(item.tipoVenda)
      "
    >
      <b-form-group label="Paciente">
        <v-select
          :options="[
            { Nome: 'Titular' },
            ...(fidelizacaoObj
              ? fidelizacaoObj.fidelizacao.dependentes
              : convenio.dependentes),
          ]"
          :label="fidelizacaoObj ? 'Nome' : 'razao_social'"
          @input="onDependenteSelecionado"
          v-model="item.dependenteSelecionado"
        />
      </b-form-group>
    </div>

    <div
      class="col-12 mt-2 pr-0 pl-0"
      v-if="
        !fidelizacaoObj &&
        (!convenio ||
          (convenio &&
            Array.isArray(convenio.dependentes) &&
            convenio.dependentes.length <= 0)) &&
        ['Consulta', 'Exame'].includes(item.tipoVenda)
      "
    >
      <b-form-group label="Paciente">
        <select-paciente
          :showBotaoAdd="true"
          :showBotaoEdit="true"
          @edit="onPacienteEdit"
          @new="onPacienteNew"
          :selecionado="
            item.dependenteSelecionado
              ? item.dependenteSelecionado.id
              : cliente
              ? cliente.id
              : cliente
          "
          @paciente="onDependenteSelecionado"
          setNullOnEmpty
          :tipo="['paciente']"
          ref="SelectPacienteRef"
        />
      </b-form-group>
    </div>

    <div
      class="row"
      v-if="
        ['Consulta', 'Produto', 'Fidelização', 'Exame'].includes(item.tipoVenda)
      "
    >
      <!-------SELECIONA FORNECEDOR------>

      <div
        class="col-12 mt-3"
        v-if="item.tipoVenda == 'Consulta' || item.tipoVenda == 'Exame'"
      >
        <label>{{
          item.tipoVenda == "Consulta" ? "Médico / Fornecedor" : "Laboratório"
        }}</label>
        <select-paciente
          :showBotaoAdd="false"
          :selecionado="item.entidadeId"
          @paciente="setFornecedor"
          setNullOnEmpty
          :tipo="
            item.tipoVenda == 'Consulta'
              ? ['medico']
              : item.tipoVenda == 'Exame'
              ? ['laboratorio']
              : null
          "
        />
      </div>

      <!-------- SELECIONA PRODUTO -------->
      <div
        class="col-12 mt-3"
        v-if="item.tipoVenda != 'Exame'"
      >
        <label>
          {{
            item.tipoVenda == "Consulta"
              ? "Consulta / Exame / Sessão"
              : item.tipoVenda
          }}
        </label>
        <!-- <select-exames
           v-model="item.exameSelecionado"
           :selecionado="item.exameSelecionado?item.exameSelecionado.id:''"
            class="style-chooser"
           :tipo="item.tipoVenda"
            @select="selectProduto"
            :showBotaoAdd="false"
            :showBotaoEdit="false">
            
          </select-exames> -->
        <!-- {{item.exameSelecionado}} -->

        <b-select
          v-model="item.exameSelecionado"
          class="style-chooser"
          :options="exames"
          @change="selectProduto"
          label="nome"
        />
      </div>
    </div>
    <!------SOLICITACAO EXAMES ----------->
    <div
      class="row mt-3"
      v-if="item.tipoVenda == 'Exame'"
    >
      <div class="col-12">
        <b>Solicitações de Exame</b>
        <v-select
          :options="solicitacoesExame"
          label="lbl"
          v-model="item.solicitacaoExame"
        />

        <div class="text-left mt-1">
          <b-btn
            variant="primary"
            @click="adicionarSolicitacaoExame"
            ><b-icon-plus-circle-fill /> Adicionar Solicitação de Exame</b-btn
          >
          <b-btn
            variant="warning"
            class="float-rigth"
            @click="editarSolciitacaoExame"
            v-if="item.solicitacaoExame"
          >
            <i class="fa fa-edit" /> Editar
          </b-btn>
        </div>
      </div>
      <div
        class="col-12"
        v-if="item.solicitacaoExame"
      >
        <table class="table">
          <thead>
            <tr>
              <th>Exame</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="exame in item.solicitacaoExame.exames"
              :key="exame.id"
              :class="{ 'text-danger': !exame.entidade_exame_id }"
            >
              <td>
                {{ exame.nome }}<br /><small
                  ><small>{{ exame.descricao }}</small></small
                >
              </td>
              <td>
                <div v-if="item.entidadeSelecionada && exame.valor_venda">
                  <span
                    :style="
                      exame.desconto
                        ? 'text-decoration: line-through; color:red;font-style: italic; '
                        : ''
                    "
                    >{{ exame.valor_venda | currencyWithMask }}
                  </span>

                  <div v-if="exame.desconto">
                    {{
                      (exame.valor_venda - exame.valor_desconto)
                        | currencyWithMask
                    }}
                  </div>
                </div>
                <div v-if="!item.entidadeSelecionada">
                  <small>Selecione o laboratório</small>
                </div>

                <div
                  v-if="
                    item.entidadeSelecionada && exame.entidade_exame_id == 0
                  "
                >
                  <b>LABORATÓRIO NÃO OFERECE ESTE EXAME</b>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!---------DESCRICAO------->
    <small
      class="p-3"
      v-if="['Exame', 'Produto'].includes(item.tipoVenda)"
    >
      {{ item.exameSelecionado ? item.exameSelecionado.descricao : "" }}
    </small>
    <!---------- CARTAO -------->

    <venda-item-fidelizacao
      :item="item"
      v-if="
        item.tipoVenda == 'Fidelização' &&
        item.exameSelecionado &&
        item.exameSelecionado.fidelizcao
      "
      :titular="cliente"
      v-model="fidelizacao"
      :id="item.exameSelecionado.id"
      ref="fidelComp"
    />

    <!--------EXAME SELECIONADO - VALORES ------------>
    <div
      class="row pr-3 mt-3"
      v-if="item.exameSelecionado || item.solicitacaoExame"
    >
      <div
        class="col-6 text-center"
        v-if="['Exame', 'Consulta'].includes(item.tipoVenda)"
      >
        <div v-if="item.exameSelecionado && !item.exameSelecionado.emite_guia">
          <b class="text-muted">{{ item.tipoVenda }} não emite guia.</b>
        </div>
        <div v-else-if="item.exameSelecionado">
          <b-form-checkbox
            v-model="item.gera_guia"
            :value="1"
            :unchecked-value="0"
            class="form-control"
            switch
          >
            Gerar guia
          </b-form-checkbox>
        </div>
      </div>
      <div class="col-6 text-right">
        <label>Valor: {{ item.valor_venda | currency }}</label>
        <div v-if="item.desconto">
          <label>Desconto/Acresimo: {{ item.valor_desconto | currency }}</label>
        </div>
        <div v-if="item.desconto">
          <label>Total: {{ item.valor_venda | currency }}</label>
        </div>
        <!-- <b-btn @click="calculaValores">teste</b-btn> -->
      </div>
      <div
        class="col-12 text-right"
        v-if="precoPersonalizado"
      >
        <label
          >Acrescimo / Desconto:
          {{
            ((precoPersonalizado.valorDeCusto === true
              ? item.exameSelecionado.valor_venda -
                precoPersonalizado.valor_custo
              : item.exameSelecionado.valor_venda -
                precoPersonalizado.valor_venda) *
              -1)
              | currency
          }}</label
        >
      </div>
      <div
        class="col-12 text-right"
        v-if="item.fidelizacaoPreco"
      >
        <label>
          {{
            item.fidelizacaoPreco.valor_venda >
            item.exameSelecionado.valor_venda
              ? "Acrescimo"
              : "Desconto"
          }}
          Fidelização:
          {{
            ((item.fidelizacaoPreco.valor_venda >
            item.exameSelecionado.valor_venda
              ? item.fidelizacaoPreco.valor_venda -
                item.exameSelecionado.valor_venda
              : item.exameSelecionado.valor_venda -
                item.fidelizacaoPreco.valor_venda) *
              -1)
              | currency
          }}</label
        >
      </div>
      <div
        class="col-12 text-right"
        v-if="item.fidelizacaoPreco"
      >
        <label
          >Total Item: {{ item.fidelizacaoPreco.valor_venda | currency }}</label
        >
      </div>
      <div class="col-12 text-center text-white bg-danger mt-2">
        Valor sujeito a alteração após adicionar na venda.***
      </div>
      <div
        class="col-12 text-right"
        v-if="precoPersonalizado"
      >
        <label
          >Total Item:
          {{
            (precoPersonalizado.valorDeCusto === true
              ? precoPersonalizado.valor_custo
              : precoPersonalizado.valor_venda) | currency
          }}</label
        >
      </div>
    </div>
    <div
      class="row mt-3"
      v-if="
        item.gera_guia &&
        item.exameSelecionado &&
        item.exameSelecionado.emite_guia &&
        item.tipoVenda === 'Consulta'
      "
    >
      <div class="col-sm-3 col-12">
        <b-form-group>
          <label>Data da Consulta: </label>
          <b-form-input
            v-model="item.data_consulta"
            type="date"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-sm-3 col-12">
        <b-form-group>
          <label>Hora da Consulta: </label>
          <b-form-input
            v-model="item.hora_consulta"
            type="time"
            locale="br"
            step="0"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 text-right">
        <hr />
        <b-button
          variant="success"
          @click="salvarItem"
          :disabled="saving"
        >
          <span v-if="value != null"> <i class="fa fa-edit" /> Editar </span>
          <span v-if="!value"> <i class="fa fa-plus"></i> Adicionar </span>
        </b-button>
        <b-button
          variant="danger"
          @click="cancelar"
          ><i class="fa fa-ban"></i> Cancelar</b-button
        >
      </div>
    </div>

    <!-------MODAIS------->

    <b-modal
      id="modal-dependente-form"
      no-enforce-focus
      hide-footer
      size="lg"
      modal-class="modalMaiorFull"
      body-class="modalNoPadding"
      hide-header
      @close="closeModalPaciente"
    >
      <paciente-ficha
        :paciente="dependenteSelecionado"
        @close="closeModalPaciente"
        closeOnSave
      />
    </b-modal>
    <b-modal
      id="modal-solic-exame"
      title="Solicitação de Exame"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <paciente-exame-form
        :showButtons="true"
        insideVenda
        :laboratorio="item.entidadeSelecionada"
        :solicitacao_edit="solicitacaoExameEdit"
        @salvar="salvarSolicitacaoExame"
        @close="cancelarSolicitacaoExame"
      />
    </b-modal>

    <b-modal
      v-if="pacienteSelecionado"
      id="modal-paciente-form"
      no-enforce-focus
      hide-footer
      size="lg"
      modal-class="modalMaiorFull"
      body-class="modalNoPadding"
      hide-header
      @close="closeModalPaciente"
    >
      <paciente-ficha
        :paciente_id="pacienteSelecionado.id"
        @close="onDependenteSelecionado"
        closeOnSave
      />
    </b-modal>
  </div>
</template>

<script>
import SolicitacaoExameLib from "../../../libs/SolicitacaoExamesLib";
import SelectPaciente from "../../common/SelectPaciente.vue";
import ExamesLib from "../../../libs/ExamesLib.js";
import VendaItemFidelizacao from "./VendaItemFidelizacao.vue";
import PacienteExameForm from "../../Entidades/Paciente/PacienteExames/PacienteExameForm.vue";
//import SelectExames from "../../common/SelectExames.vue";
import moment from "moment";
import Validadores from "../../../helpers/Validadores.js";
export default {
  components: {
    SelectPaciente,
    VendaItemFidelizacao,
    PacienteExameForm,
    PacienteFicha: () =>
      import("@/components/Entidades/Paciente/PacienteFicha.vue"),
    // SelectExames
  },
  props: {
    fidelizacaoObj: {
      type: Object,
      default: null,
    },
    convenio: Object,
    value: Object,
    cliente: Object,
    horario: Object,
    funcionario: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.clienteOriginal = JSON.parse(JSON.stringify(this.cliente));
    this.carregarExames();
    this.carregarSolicitacoesExames();
    this.carregarEdicao();

    ////////console.log(this.cliente, this.fidelizacaoObj, this.convenio);
    if (!this.fidelizacaoObj) {
      this.item.dependenteSelecionado = this.cliente;
    }

    await this.$nextTick();
    this.opened = true;
    //////console.log("cli", this.clienteOriginal);

    if (this.horario && this.horario.entidade_id && this.horario.MedicoNome) {
      this.item.tipoVenda = "Consulta";
      this.$nextTick(() => {
        this.setFornecedor({
          id: this.horario.entidade_id,
          razao_social: this.horario.MedicoNome,
        });
      });
    }
  },
  data() {
    return {
      clienteOriginal: null,
      exames: [],
      item: {
        idInt: 0,
        tipoVenda: null,
        entidadeId: 0,
        entidadeSelecionada: null,
        exameSelecionado: null,
        gera_guia: 1,
        data_consulta: null,
        hora_consulta: null,
        fidelizacaoPreco: null,
        valor_venda: 0,
        valor_custo: 0,
        valor_desconto: 0,
        dependenteSelecionado: { Nome: "Titular", razao_social: "Titular" },
        solicitacaoExame: null,
      },
      paciente: null,
      fidelizacao: null,
      solicitacoesExame: [],
      solicitacaoExameEdit: null,
      dependenteSelecionado: null,
      pacienteSelecionado: null,
      opened: false,

      ultimosExameEntidadeID: 0,
      saving: false,
      precoPersonalizado: null,
    };
  },
  watch: {
    "item.tipoVenda": function () {
      ////////console.log('changeTipo', !this.value || this.value && this.value.tipoVenda != this.item.tipoVenda);
      this.solicitacaoExame = null;
      if (
        !this.value ||
        (this.value && this.value.tipoVenda != this.item.tipoVenda)
      ) {
        this.item.exameSelecionado = null;
        this.item.entidadeId = 0;
        this.item.entidadeSelecionada = null;
        this.$nextTick();
        this.$forceUpdate();
        this.carregarExames();
      }
    },

    "item.entidadeId": function () {
      this.carregarExames();
    },
    "item.gera_guia"() {
      if (!this.item.gera_guia) {
        this.item.data_consulta = "";
        this.item.hora_consulta = "";
      }
      // else {
      //   this.item.data_consulta = moment().format("YYYY-MM-DD");
      //   this.item.hora_consulta = moment().format("HH:mm");
      // }
    },
    "item.exameSelecionado": {
      deep: true,
      handler() {
        this.calculaValores();
        this.$forceUpdate();
        // if (this.item.gera_guia) {
        //   this.item.data_consulta = moment().format("YYYY-MM-DD");
        //   this.item.hora_consulta = moment().format("HH:mm");
        // }
      },
    },
    "item.solicitacaoExame": function () {
      this.carregarValorSolicExame();
    },

    value: {
      deep: true,
      handler() {
        this.carregarEdicao();
      },
    },
    cliente: function () {
      this.carregarSolicitacoesExames();
    },
  },
  computed: {},
  methods: {
    onPacienteEdit(pac) {
      this.pacienteSelecionado = pac;
      this.$bvModal.show("modal-paciente-form");
    },
    onPacienteNew() {
      this.pacienteSelecionado = {
        id: "",
        razao_social: "",
        fantasia: "",
        crm: "",
        ie: "",
        cpf: "",
        email: "",
        enderecos: [],
        telefones: [],
        tipo: "PF",
        status: 1,
        // tipo_pessoa: "PF",
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
          },
        },

        convenio: 0,
        fornecedor: 0,
        laboratorio: 0,
        medico: 0,
        paciente: 1,
      };
      // //////console.log("ON NEWW");
      this.$bvModal.show("modal-paciente-form");
    },
    selectProduto(prod) {
      //////console.log("prod", prod);
      this.item.valor_venda = prod.valor_venda;
      this.item.valor_original = prod.valor_venda;
      this.item.valor_custo = prod.valor_custo;
      this.precoPersonalizado = null;
      if (
        this.funcionario === true &&
        prod &&
        prod.configuracoes &&
        prod.configuracoes.personalizarPorForma &&
        prod.configuracoes.personalizarPorForma.length > 0
      ) {
        let hasPer = prod.configuracoes.personalizarPorForma.find(
          (x) => x.formaDePagamento == "DEF"
        );
        //////console.log("finded forma personalizado", hasPer);
        if (hasPer) {
          this.precoPersonalizado = hasPer;
        }
      }
      if (
        this.convenio &&
        prod &&
        prod.configuracoes &&
        prod.configuracoes.personalizarPorForma &&
        prod.configuracoes.personalizarPorForma.length > 0
      ) {
        let hasPer = prod.configuracoes.personalizarPorForma.find(
          (x) =>
            x.formaDePagamento === "Convenio" &&
            x.empresa &&
            x.empresa.id == this.convenio.convenio_id
        );
        if (hasPer) {
          this.precoPersonalizado = hasPer;
        }
      }
    },
    async onDependenteSelecionado(dependente) {
      if (dependente && this.cliente.id != dependente.id) {
        console.log("dpeendente: ", dependente);
        const isIncompleto =
          dependente &&
          (!dependente.cpf || (dependente.cpf && dependente.cpf.trim() === ""));
        if (isIncompleto) {
          this.showToast(
            "error",
            "Cadastro do dependente está incompleto... Verifique!",
            5000
          );
          console.log("OpenDpeendente: ", dependente);
          this.item.dependenteSelecionado = dependente;
          this.openModalPaciente(dependente);
        }
        this.item.dependenteSelecionado = dependente;
      } else {
        //this.item.dependenteSelecionado = null;
        this.item.dependenteSelecionado = dependente;
      }
    },
    closeModalPaciente(paciente) {
      console.log("closeModalPaciente", paciente);
      if (paciente) {
        let dependente;
        if (
          this.fidelizacaoObj &&
          Array.isArray(this.fidelizacaoObj.dependentes)
        ) {
          dependente = this.fidelizacaoObj.dependentes.find(
            (d) => d.entidade_id === paciente.id || d.CPF === paciente.cpf
          );
        } else if (this.covnenio && Array.isArray(this.covnenio.dependentes)) {
          dependente = this.covnenio.dependentes.find(
            (d) => d.entidade_id === paciente.id || d.CPF === paciente.cpf
          );
        }
        if (dependente) {
          dependente.Nome = paciente.razao_social;
          dependente.entidade_id = paciente.id;
          dependente.CPF = paciente.cpf;
        }
        this.dependenteSelecionado = paciente;
        const isIncompleto =
          dependente &&
          (!dependente.CPF || (dependente.cpf && dependente.cpf.trim() === ""));
        if (isIncompleto) {
          this.dependenteSelecionado = null;
          this.pacienteSelecionado = null;

          console.log("Incompleto 02");
        }
      } else {
        if (
          this.$refs.SelectPacienteRef &&
          this.$refs.SelectPacienteRef.deselect
        ) {
          this.$refs.SelectPacienteRef.deselect();
        }
        this.dependenteSelecionado = null;
        this.pacienteSelecionado = null;
        this.item.dependenteSelecionado = null;
        console.log("Incompleto 01");
        this.$forceUpdate();
      }
      this.$bvModal.hide("modal-dependente-form");
      this.$bvModal.hide("modal-cliente-form");
    },
    openModalPaciente(paciente) {
      if (paciente && (paciente.id || paciente.entidade_id)) {
        this.dependenteSelecionado = {
          razao_social: paciente.Nome,
          id: paciente.entidade_id || paciente.id,
          cpf: paciente.CPF,
        };
        this.$forceUpdate();
        console.log("abrindo cadastro", this.dependenteSelecionado.id);
        this.$bvModal.show("modal-dependente-form");
      }
    },
    salvarSolicitacaoExame(solicitacao) {
      if (!this.solicitacaoExameEdit) {
        this.solicitacoesExame.push(solicitacao);
      } else {
        this.solicitacoesExame[
          this.solicitacoesExame.findIndex((x) => x.id === solicitacao.id)
        ] = solicitacao;
      }

      this.item.solicitacaoExame = solicitacao;
      this.$forceUpdate();
      this.cancelarSolicitacaoExame();
    },

    editarSolciitacaoExame() {
      this.solicitacaoExameEdit = this.item.solicitacaoExame;
      this.$bvModal.show("modal-solic-exame");
    },
    cancelarSolicitacaoExame() {
      this.solicitacaoExameEdit = null;
      this.$bvModal.hide("modal-solic-exame");
    },
    adicionarSolicitacaoExame() {
      this.solicitacaoExameEdit = null;
      this.$bvModal.show("modal-solic-exame");
    },
    calculaValores() {
      //  //////console.log("CHAMOU calculaValores()");
      // //////console.log(this.item, this.fidelizacaoObj);
      //////console.log(this.item);
      this.item.fidelizacaoPreco = null;
      if (
        this.fidelizacaoObj &&
        this.item.exameSelecionado &&
        this.item.exameSelecionado.configuracoes &&
        this.item.exameSelecionado.configuracoes.fidelizacoes
      ) {
        this.item.fidelizacaoPreco =
          this.item.exameSelecionado.configuracoes.fidelizacoes.find(
            (x) => x.id === this.fidelizacaoObj.fidelizacao_id
          );
      }
      //////console.log("tipoVenda", this.item, "fidelizacao", this.fidelizacao);
      if (this.item.tipoVenda != "Exame") {
        this.item.valor_venda =
          this.fidelizacao && this.fidelizacao.valor > 0
            ? this.fidelizacao.valor
            : this.item.exameSelecionado.valor_venda;
        this.item.valor_custo = this.item.exameSelecionado.valor_custo;
      } else if (
        this.item?.tipoVenda == "Exame" &&
        this.item?.solicitacaoExame &&
        this.item?.solicitacaoExame?.exames
      ) {
        //////console.log("exs", this.item.solicitacaoExame.exames);
        this.item.valor_original = this.item.solicitacaoExame.exames.reduce(
          (ret, vl) => {
            ret += vl.valor_venda;
            return ret;
          },
          0
        );
        this.item.valor_desconto = this.item.solicitacaoExame.exames.reduce(
          (ret, vl) => {
            ret += vl.valor_desconto ? vl.valor_desconto : 0;
            return ret;
          },
          0
        );
        this.item.valor_venda =
          this.item.valor_original - (this.item.valor_desconto || 0);

        this.item.desconto = this.item.solicitacaoExame.exames.find(
          (x) => x.desconto
        )?.desconto;

        this.item.valor_custo = this.item.solicitacaoExame.exames.reduce(
          (ret, vl) => {
            ret += vl.valor_custo;
            return ret;
          },
          0
        );
        // //////console.log('this.item', this.item);
      }
    },
    async carregarSolicitacoesExames() {
      if (this.cliente && this.cliente.id) {
        let id = this.cliente.id.toString();
        this.solicitacoesExame = (
          await SolicitacaoExameLib.get({
            paciente_id: id,
          })
        ).map((it) => {
          it.exames = it.exames.map((ita) => ({
            ...ita,
            exameSelecionado: ita,
          }));
          //////console.log('it',it);
          return {
            ...it,
            lbl: `${it.id} | ${moment(it.data).format("DD/MM/YYYY")} | ${
              it.medicoNome
            }`,
          };
        });
      }
    },
    async carregarExames() {
      this.exames = [];
      if (
        (["Consulta", "Exame"].includes(this.item.tipoVenda) &&
          this.item.entidadeId) ||
        this.item.tipoVenda == "Produto" ||
        this.item.tipoVenda == "Fidelização"
      ) {
        if (
          this.ultimosExameEntidadeID !== this.item.entidadeId ||
          this.exames.length <= 0
        ) {
          this.ultimosExameEntidadeID = this.item.entidadeId;
          let exs = await ExamesLib.get(
            this.item.tipoVenda == "Consulta"
              ? "Consulta|Exame"
              : this.item.tipoVenda,
            this.item.entidadeId
          );
          if (this.item.tipoVenda == "Fidelização") {
            for (let ex of exs) {
              ex.fidelizcao.tipo_pessoa = JSON.parse(ex.fidelizcao.tipo_pessoa);
            }

            exs = exs.filter(
              (x) =>
                (x.fidelizcao &&
                  this.clienteOriginal &&
                  ((this.clienteOriginal.funcionario &&
                    this.clienteOriginal.funcionario === 1 &&
                    x.fidelizcao.tipo_pessoa.includes("Funcionário")) ||
                    (this.clienteOriginal.paciente &&
                      this.clienteOriginal.paciente === 1 &&
                      x.fidelizcao.tipo_pessoa.includes("Paciente")) ||
                    (this.clienteOriginal.medico &&
                      this.clienteOriginal.medico === 1 &&
                      x.fidelizcao.tipo_pessoa.includes("Médico")))) ||
                (this.clienteOriginal.fornecedor &&
                  this.clienteOriginal.fornecedor === 1 &&
                  x.fidelizcao.tipo_pessoa.includes("Fornecedor"))
            );
          }

          this.exames = exs.map((ex) => {
            return {
              value: ex,
              text: ex.nome,
              label: ex.nome,
            };
          });
          // //////console.log(this.exames);
          this.carregarValorSolicExame();
        }
      }
    },
    async setFornecedor(id) {
      this.item.entidadeSelecionada = id;
      this.item.entidadeId = id ? id.id : 0;
      this.carregarValorSolicExame();
    },
    carregarValorSolicExame() {
      ////////console.log("carregarValorSolicExame", this.item);

      if (this.item.solicitacaoExame && this.item.solicitacaoExame.exames) {
        ////////console.log("entrou2", this.exames);
        //////console.log("exameSolicitacao", this.item.solicitacaoExame.exames);
        for (let exame of this.item.solicitacaoExame.exames) {
          let res = this.exames.find((x) => x.value.id == exame.exameId);
          // //////console.log("RES", res);
          if (res) {
            res = res.value;
            exame.valor_venda = res.valor_venda;
            exame.valor_custo = res.valor_custo;
            exame.entidade_exame_id = res.entidade_exame_id;
            exame.personalizar_por_forma = res.personalizar_por_forma;
            exame.configuracoes = res.configuracoes;
            exame.aceita_desconto = res.aceita_desconto;

            exame.exameSelecionado.valor_venda = res.valor_venda;
            exame.exameSelecionado.valor_custo = res.valor_custo;
            exame.exameSelecionado.entidade_exame_id = res.entidade_exame_id;
            exame.exameSelecionado.personalizar_por_forma =
              res.personalizar_por_forma;
            exame.exameSelecionado.configuracoes = res.configuracoes;
            exame.exameSelecionado.aceita_desconto = res.aceita_desconto;

            if (
              this.funcionario === true &&
              res &&
              res.configuracoes &&
              res.configuracoes.personalizarPorForma &&
              res.configuracoes.personalizarPorForma.length > 0
            ) {
              let hasDEF = res.configuracoes.personalizarPorForma.find(
                (x) => x.formaDePagamento == "DEF"
              );

              if (hasDEF) {
                exame.desconto = hasDEF;
                exame.valor_desconto =
                  res.valor_venda -
                  (hasDEF.valorDeCusto
                    ? hasDEF.valor_custo
                    : hasDEF.valor_venda);
              }
            }
            if (
              this.convenio &&
              res &&
              res.configuracoes &&
              res.configuracoes.personalizarPorForma &&
              res.configuracoes.personalizarPorForma.length > 0
            ) {
              let hasConv = res.configuracoes.personalizarPorForma.find(
                (x) =>
                  x.formaDePagamento === "Convenio" &&
                  x.empresa &&
                  x.empresa.id == this.convenio.convenio_id
              );
              if (hasConv) {
                exame.desconto = hasConv;
                exame.valor_desconto =
                  res.valor_venda -
                  (hasConv.valorDeCusto
                    ? hasConv.valor_custo
                    : hasConv.valor_venda);
              }
            }
          } else {
            exame.valor_venda = 0;
            exame.valor_custo = 0;
            exame.entidade_exame_id = 0;
          }
        }

        this.calculaValores();
      }
    },
    cancelar() {
      this.$emit("close");
    },
    async salvarItem(e) {
      // //////console.log('salvarItem has been called');
      if (e) e.preventDefault();

      if (this.saving) {
        return;
      }

      console.log("vendaItem", this.item);

      if (
        this.item &&
        this.item.dependenteSelecionado &&
        this.item.dependenteSelecionado.Nome != "Titular" && 
        (!this.item.dependenteSelecionado.cpf ||
          this.item.dependenteSelecionado.cpf == "" ||
          this.item.dependenteSelecionado.cpf == "000.000.000-00" ||
          !Validadores.string.validarCPF(this.item.dependenteSelecionado.cpf)) && 
          (!this.item.dependenteSelecionado.CPF ||
          this.item.dependenteSelecionado.CPF == "" ||
          this.item.dependenteSelecionado.CPF == "000.000.000-00" ||
          !Validadores.string.validarCPF(this.item.dependenteSelecionado.CPF)) 
      ) {
        this.$swal({
          title: "Atenção",
          html: `<b>CPF do paciente inválido</b><hr/><span class='text-left'>Informe um CPF válido para o dependente</span>`,
          icon: "error",
        });
        return;
      }

      let erros = [];
      if (!this.item.tipoVenda) {
        erros.push("Selecione o tipo de venda.");
      }
      if (
        ["Consulta", "Exame"].includes(this.item.tipoVenda) &&
        (!this.item.entidadeId || this.item.entidadeId == 0)
      ) {
        erros.push("Informe um Médico/Laboratório/Fornecedor");
      }
      if (
        ["Consulta"].includes(this.item.tipoVenda) &&
        this.item.gera_guia === 1 &&
        this.item.exameSelecionado.emite_guia === 1
      ) {
        if (!this.item.hora_consulta) {
          erros.push("Informe a hora da consulta!");
        }
        if (!this.item.data_consulta) {
          erros.push("Informe a data da consulta!");
        }
      }
      if (
        ["Consulta", "Produto", "Fidelização"].includes(this.item.tipoVenda) &&
        !this.item.exameSelecionado
      ) {
        erros.push("Selecione uma Consulta / Exame / Produto");
      }
      //  //////console.log('tem fidelizacao', this.fidelizacao);
      if (this.fidelizacao) {
        await this.$refs.fidelComp.emitInput();
        ////////console.log('FIDELIZACAO',this.fidelizacao);

        this.item.fidelizacao = this.fidelizacao;
        this.item.valor_venda = this.fidelizacao.valor;
        ////////console.log('item valor_venda', this.item.valor_venda);
        if (
          this.fidelizacao.dependentes.some((depend) => depend.equalToTitular)
        ) {
          erros.push("O titular não pode ser um dependente.");
        }
        //erros.push("teste");
        // //////console.log(
        //   "this.items.exameSelecionado",
        //   this.item.exameSelecionado,
        //   "this.fidelizacao",
        //   this.fidelizacao
        // );
        if (this.item.exameSelecionado.fidelizcao && this.fidelizacao) {
          // if (
          //   this.item.exameSelecionado.fidelizcao.qtd_dependentes >
          //   this.fidelizacao.dependentes.filter(
          //     (x) => x.Nome && x.Nome.trim() != ""
          //   ).length
          // ) {
          //   erros.push(
          //     "A quantidade de dependentes informados é menor que a quantidade disponível."
          //   );
          // }

          if (!this.fidelizacao.codigo || this.fidelizacao.codigo.length < 9) {
            erros.push("Código do cartão inválido!");
          }
          if (!this.fidelizacao.codigoLivre) {
            erros.push("O código informado já foi utilizado em outro cartão!");
          }
        }
      }

      if (this.item.tipoVenda == "Exame") {
        if (
          !this.item.solicitacaoExame ||
          !this.item.solicitacaoExame.exames ||
          this.item.solicitacaoExame.exames.length <= 0
        ) {
          erros.push("Selecione uma solicitação de exame.");
        }
        if (
          this.item.solicitacaoExame &&
          this.item.solicitacaoExame.exames &&
          this.item.solicitacaoExame.exames.filter(
            (x) => !x.entidade_exame_id || x.entidade_exame_id == 0
          ).length > 0
        ) {
          erros.push(
            "Existe 1 ou mais exames que não possui venda para o laboratório selecionado"
          );
        }
      }

      if (erros.length > 0) {
        let errDesc = erros.reduce((it, vl) => {
          it += vl + " <hr />";
          return it;
        }, "");
        // //////console.log(errDesc);
        this.$swal({
          title: "Atenção",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errDesc}</span>`,
          icon: "error",
        });
      } else {
        if (this.saving) return;
        // this.item.valor_venda =
        //   this.item.valor_venda > 0
        //     ? this.item.valor_venda
        //     : this.item.exameSelecionado.valor_venda;
        // this.item.valor_custo = this.item.exameSelecionado.valor_custo;
        // //////console.log(this.item);
        this.saving = true;
        await this.$nextTick();
        //////console.log("enviando item", Object.assign({}, { ...this.item }))
        this.$emit("adicionarItem", this.item);
      }
    },
    carregarEdicao() {
      this.$nextTick();
      if (this.value) {
        // this.item = Object.assign(this.item,this.value);
        // this.$forceUpdate();
        // //////console.log('=',this.item)
        this.item.tipoVenda = this.value.tipoVenda;
        this.$nextTick();
        this.carregarExames();
        this.$nextTick();

        // //////console.log("exames", this.exames);

        // this.item.entidadeId = this.value.entidadeId;
        // this.item.entidadeSelecionada = this.value.entidadeSelecionada;
        this.setFornecedor(this.value.entidadeSelecionada);
        this.$nextTick();
        this.item.idInt = this.value.idInt;
        this.item.exameSelecionado = this.value.exameSelecionado;
        this.fidelizacao = this.value.fidelizacao;
        this.$nextTick();
        this.fidelizacao = this.value.fidelizacao;

        this.item.entidadeId = this.value.entidadeId;
        // //////console.log(this.item.entidadeId, this.value.entidadeId);
        this.item.entidadeSelecionada = this.value.entidadeSelecionada;
        this.item.solicitacaoExame = this.value.solicitacaoExame;
        this.$nextTick();

        this.$forceUpdate();
        // //////console.log("====.>", this.value, this.item);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
